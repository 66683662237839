import { alert } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProcedureInputType, ProcedureType } from 'types/procedures'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import ProcedureForm from 'components/Procedures/ProcedureForm'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { ProcedureInputSchema } from 'schemas/procedure'

const ProcedureEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const {
    data: procedure,
    doRequest: getProcedure,
    isLoading,
  } = useRequest<ProcedureType, { id: string }>(api.getProcedure)

  const form = useFormManager<ProcedureInputType>({
    defaultValues: {
      ...procedure,
      splitProcedures: Array.isArray(procedure?.splitProcedures)
        ? procedure?.splitProcedures.map(item =>
            (typeof item === 'object' && 'id' in item ? item.id : item),
          )
        : [],
      numberOfStemCells:
        Number(procedure?.numberOfStemCells) > 0
          ? procedure?.numberOfStemCells
          : null,
    },
    enableReinitialize: true,
    schema: ProcedureInputSchema,
    onCancel: () => navigate(`${RootPath.PROCEDURES}/${id}`),
    onSubmit: async input => {
      if (id) {
        await api.updateProcedure({ id, input })
        navigate(`${RootPath.PROCEDURES}/${id}`)
        alert.success(
          `${t('Success')}!`,
          `${input.name} ${t('was edited successfully')}.`,
          {
            position: 'bottom-right',
          },
        )
      }
    },
  })

  useEffect(() => {
    if (id) getProcedure({ id })
  }, [getProcedure, id])

  return (
    <PageTemplate
      title={t('Edit Procedure')}
      goBackTo={`${RootPath.PROCEDURES}/${id}`}
    >
      <ProcedureForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default ProcedureEdit
