import { Flex, Text } from '@weareredlight/design-system'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'
import { Views } from 'react-big-calendar'

import type {
  AppointmentType,
  OrganizationAppointmentsParams,
} from 'types/appointments'
import type { OrganizationType } from 'types/organizations'
import type { PaginatedRequest } from 'types/types'

import AppointmentDetails from '../Appointments/AppointmentDetails'

import api from 'api/api'
import BigCalendar from 'components/BigCalendar'
import Card from 'components/Card'
import ScheduleFilters from 'components/Schedule/ScheduleFilters'
import { useRequest } from 'hooks/useRequest'
import { CalendarViews, TreatmentStatuses } from 'utils/enums'
import { getName } from 'utils/text'

type CalendarViewType = {
  selectedOrg: OrganizationType
  defaultDate: string | Date
  view: CalendarViews
  showFilters: boolean
  calendarFilters?: OrganizationAppointmentsParams
  setCalendarFilters: (filters?: OrganizationAppointmentsParams) => void
}

const CalendarView = ({
  selectedOrg,
  defaultDate,
  showFilters,
  view,
  calendarFilters,
  setCalendarFilters,
}: CalendarViewType) => {
  const {
    data: appointments,
    doRequest: getAppointments,
    isLoading: isLoadingAppointments,
  } = useRequest<
    PaginatedRequest<AppointmentType>,
    OrganizationAppointmentsParams
  >(api.getAppointments)

  const fetchAppointments = useCallback(() => {
    getAppointments({
      ...calendarFilters,
      organizationId: selectedOrg.id,
      year: dayjs(defaultDate).year(),
      week: dayjs(defaultDate).week(),
      startDateTime:
        view === CalendarViews.DAY
          ? dayjs(defaultDate).format('YYYY-MM-DDT00:00:00')
          : '',
      endDateTime:
        view === CalendarViews.DAY
          ? dayjs(defaultDate).format('YYYY-MM-DDT23:59:59')
          : '',
    })
  }, [getAppointments, selectedOrg, calendarFilters, defaultDate, view])

  // update appointments when filters change
  useEffect(() => {
    fetchAppointments()
    // update appointments every 15 minutes
    const interval = setInterval(() => {
      fetchAppointments()
    }, 300000)
    return () => clearInterval(interval)
  }, [fetchAppointments])

  const getEventType = (event: AppointmentType) => {
    const procedure = event?.treatmentProcedure?.procedure
    if (!procedure) {
      return { className: '' }
    }
    return {
      className:
        procedure?.numberOfStemCells && procedure.numberOfStemCells > 0
          ? 'cell-bank-event'
          : '',
    }
  }

  const appointmentsAsEvents: AppointmentType[] = useMemo(() => {
    if (!appointments) return []
    const events = appointments.data
      .filter(appointment => {
        const { status } = appointment.treatmentProcedure.treatment
        return status !== TreatmentStatuses.CANCELLED
      })
      .map(appointment => {
        return {
          ...appointment,
          start: dayjs(appointment?.startTime).toDate(),
          end: dayjs(appointment?.endTime).toDate(),
        }
      })
    return events
  }, [appointments])

  const eventComponent = useMemo(
    () => ({
      event: (event: { event: AppointmentType }) => {
        const appointmentEvent = event.event
        const { procedure, treatment } = appointmentEvent.treatmentProcedure
        return (
          <div className="event-details">
            <Flex direction="column" align="start">
              <Text variant="microCopy" color="white">
                {getName(treatment, 'patient')}
              </Text>
              <Text variant="microCopy" color="primary200">
                {String(procedure.name)}
              </Text>
            </Flex>
            <div className="event-trigger">
              <AppointmentDetails
                appointment={appointmentEvent}
                position={
                  view.toLocaleLowerCase() === Views.DAY ? 'bottom' : undefined
                }
              />
            </div>
          </div>
        )
      },
    }),
    [view],
  )

  return (
    <>
      {showFilters && (
        <ScheduleFilters
          organizationId={selectedOrg.id}
          getAppointmentFilters={setCalendarFilters}
        />
      )}
      <Card extraClasses="wrapper full-width" isLoading={isLoadingAppointments}>
        <BigCalendar
          date={defaultDate}
          events={appointmentsAsEvents}
          scrollToTime={dayjs(defaultDate).hour(8).minute(0).toDate()}
          onSelecting={() => false}
          draggableAccessor={() => false}
          toolbar={false}
          view={view?.toLocaleLowerCase()}
          style={{ height: 560 }}
          onNavigate={() => false}
          onView={() => false}
          eventPropGetter={getEventType}
          components={eventComponent}
        />
      </Card>
    </>
  )
}

export default CalendarView
