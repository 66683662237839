import { useEffect } from 'react'

import type { ColumnDef } from '@tanstack/react-table'
import type {
  AppointmentsParams,
  AppointmentType,
  OrganizationAppointmentsParams,
} from 'types/appointments'
import type { OrganizationType } from 'types/organizations'
import type { CalendarViews } from 'utils/enums'

import api from 'api/api'
import ScheduleFilters from 'components/Schedule/ScheduleFilters'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'

type AppointmentsListProps = {
  calendarView: CalendarViews
  columns: ColumnDef<AppointmentType>[]
  defaultDate: string | Date
  selectedOrg: OrganizationType
  showFilters: boolean
  params: AppointmentsParams | OrganizationAppointmentsParams
  onRowClick?: (data: AppointmentType) => void
  setCalendarFilters: (filters?: OrganizationAppointmentsParams) => void
}

const AppointmentsList = ({
  columns,
  params,
  selectedOrg,
  showFilters,
  setCalendarFilters,
  onRowClick,
}: AppointmentsListProps) => {
  const { tableData, refreshTable, ...rest } = useTableRequestState<
    AppointmentType,
    AppointmentsParams | OrganizationAppointmentsParams
  >({
    requestFunc: api.getAppointments,
    params,
    pageSize: 20,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      refreshTable()
    }, 300000)

    return () => clearInterval(interval)
  }, [refreshTable])

  return (
    <>
      {showFilters && (
        <ScheduleFilters
          organizationId={selectedOrg.id}
          getAppointmentFilters={setCalendarFilters}
        />
      )}
      <Table<AppointmentType>
        data={tableData?.data || []}
        columns={columns}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        onRowClick={onRowClick}
        {...rest}
      />
    </>
  )
}

export default AppointmentsList
