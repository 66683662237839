import { Input, Flex, Text, TextArea } from '@weareredlight/design-system'
import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import type { OrganizationType } from 'types/organizations'
import type { TreatmentInputType } from 'types/treatments'
import type { FormType, PaginatedRequest } from 'types/types'

import api from 'api/api'
import Card from 'components/Card'
import {
  Form,
  ControlledSelect,
  ControlledToggle,
  ControlledDatePicker,
} from 'components/Form'
import { TreatmentFields } from 'components/Treatments/utils'
import { useRequest } from 'hooks/useRequest'

const planInDaysOptions = [
  { value: '1', label: '1 Day' },
  { value: '3', label: '3 Days' },
  { value: '5', label: '5 Days' },
]

const TreatmentForm = ({
  form,
  isLoading,
  ...props
}: FormType<TreatmentInputType>) => {
  const { data: organizations, doRequest: getOrganizations } = useRequest<
    PaginatedRequest<OrganizationType>
  >(api.getOrganizations)

  const { t } = useTranslation()
  const organizationOptions = useMemo(
    () =>
      organizations?.data?.map(organization => ({
        value: organization.id,
        label: organization.name,
      })) ?? [],
    [organizations],
  )

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  return (
    <Card isLoading={isLoading}>
      <Form form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Text variant="h2">{t('Treatment Details')}</Text>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledSelect
              name={`${TreatmentFields.ORGANIZATION_ID}`}
              label={t('Organization')}
              options={organizationOptions}
              placeholder={t('Select organization')}
              state={
                form.formState.errors[TreatmentFields.ORGANIZATION_ID]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[TreatmentFields.ORGANIZATION_ID]?.message
              }
              fullWidth
            />
            <ControlledDatePicker
              name={TreatmentFields.TREATMENT_DATE}
              label={`${t('Treatment date')} ${t('(Optional)')}`}
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(TreatmentFields.YEAR)}
              type="number"
              min={2023}
              label={t('Year')}
              placeholder={t('Enter year')}
              state={
                form.formState.errors[TreatmentFields.YEAR] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[TreatmentFields.YEAR]?.message}
              fullWidth
            />
            <Input
              {...form.register(TreatmentFields.WEEK)}
              type="number"
              min={1}
              label={t('Week')}
              placeholder={t('Enter week')}
              state={
                form.formState.errors[TreatmentFields.WEEK] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[TreatmentFields.WEEK]?.message}
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(TreatmentFields.NAME)}
              label={`${t('Name')} ${t('(Optional)')}`}
              placeholder={t('Enter name')}
              state={
                form.formState.errors[TreatmentFields.NAME] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[TreatmentFields.NAME]?.message}
              fullWidth
            />
            <Input
              {...form.register(TreatmentFields.INVOICE_NUMBER)}
              label={`${t('Invoice Number')} ${t('(Optional)')}`}
              placeholder={t('Enter Invoice Number')}
              state={
                form.formState.errors[TreatmentFields.INVOICE_NUMBER]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[TreatmentFields.INVOICE_NUMBER]?.message
              }
              fullWidth
            />
          </Flex>
          <TextArea
            {...form.register(TreatmentFields.COMMENTS)}
            id={TreatmentFields.COMMENTS}
            label={`${t('Comments')} ${t('(Optional)')}`}
            placeholder={t('Enter comments')}
            state={
              form.formState.errors[TreatmentFields.COMMENTS] ? 'error' : 'null'
            }
            errorMsg={form.formState.errors[TreatmentFields.COMMENTS]?.message}
            fullWidth
          />
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledSelect
              name={TreatmentFields.PLAN_IN_DAYS}
              options={planInDaysOptions}
              label={`${t('Plan in days')} ${t('(Optional)')}`}
              placeholder={t('Select plan in days')}
              state={
                form.formState.errors[TreatmentFields.PLAN_IN_DAYS]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[TreatmentFields.PLAN_IN_DAYS]?.message
              }
              fullWidth
            />
            <Input
              {...form.register(TreatmentFields.SALESFORCE_ID)}
              label={`${t('Salesforce ID')} ${t('(Optional)')}`}
              placeholder={t('Enter Salesforce ID')}
              state={
                form.formState.errors[TreatmentFields.SALESFORCE_ID]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[TreatmentFields.SALESFORCE_ID]?.message
              }
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledDatePicker
              name={TreatmentFields.SYSTEM_MOD_STAMP}
              label={`${t('System Mod Stamp')} ${t('(Optional)')}`}
            />
            <Input
              {...form.register(TreatmentFields.VISIT)}
              type="number"
              min={0}
              label={`${t('Visit')} ${t('(Optional)')}`}
              placeholder={t('Enter number of visits')}
              state={
                form.formState.errors[TreatmentFields.VISIT] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[TreatmentFields.VISIT]?.message}
              fullWidth
            />
          </Flex>
          <ControlledToggle
            name={TreatmentFields.IS_INTRATHICAL_APPLICATION}
            label={t('Is intrathical application')}
          />
        </Flex>
      </Form>
    </Card>
  )
}
export default TreatmentForm
